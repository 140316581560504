import imgProxy from "~/utils/imgproxy";

import "./FirstScreen.css";

import IconArrowBack from "~/img/icons/arrow_back.svg";
import IconArrowForward from "~/img/icons/arrow_forward.svg";
import { urlRs } from "~/utils/url";
import { createSignal, onCleanup, onMount, Show } from "solid-js";
import { isServer } from "solid-js/web";

type FirstScreenProps = {
  visual: string;
  visualMobile: string;
  title: string;
  prevLink: string;
  nextLink: string;
  isFirstEnga?: boolean;
  isLastEnga?: boolean;
};

export default function FirstScreen(props: FirstScreenProps) {
  const [barIsStacked, setBarIsStacked] = createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    const currentScrollTop = document.documentElement.scrollTop;
    if (currentScrollTop >= window.innerHeight - 100) {
      setBarIsStacked(true);
    } else {
      setBarIsStacked(false);
    }
  };

  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture class="visual">
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(props.visual, "size:1920:1080/resizing_type:fill")}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(props.visual, "size:1366:768/resizing_type:fill")}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(props.visual, "size:1024:576/resizing_type:fill")}
          />
          <source
            media="(max-width: 767px)"
            srcset={imgProxy(
              props.visualMobile,
              "size:750:1334/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              props.visualMobile,
              `size:375:667/resizing_type:fill`,
            )}
            alt=""
            height="667"
            width="375"
            loading="lazy"
          />
        </picture>

        <div class="bottom-part">
          <span class="number">Cogedim s’engage pour</span>
          <h1 innerHTML={props.title} />
        </div>

        <div class="engagements-nav">
          <div class="inner">
            <a href={urlRs("enga", props.prevLink)} class="btn prev">
              <i aria-hidden="true" class="cog-icon">
                <IconArrowBack />
              </i>
              <Show
                when={props.isFirstEnga}
                fallback={<span>Engagement précédent</span>}
              >
                <span>Nos engagements</span>
              </Show>
            </a>
            <a href={urlRs("enga", props.nextLink)} class="btn next">
              <Show
                when={props.isLastEnga}
                fallback={<span>Engagement suivant</span>}
              >
                <span>Nos engagements</span>
              </Show>
              <i aria-hidden="true" class="cog-icon">
                <IconArrowForward />
              </i>
            </a>
          </div>
        </div>
      </section>

      <section class="enga-stackbar" classList={{ stacked: barIsStacked() }}>
        <div class="gradient" />
        <a href={urlRs("front", "/")} title="Accueil" class="logo">
          <img
            src="/images/logo-cogedim.svg"
            alt="Cogedim"
            height="40"
            width="40"
          />{" "}
          Cogedim
        </a>
        <div class="flex-line">
          <div class="title-group">
            <span class="number">
              <span>Cogedim s’engage pour</span>
              <span class="engagements-nav-mobile">
                <a href={props.prevLink} class="btn btn-icon">
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowBack />
                  </i>
                </a>
                <a href={props.nextLink} class="btn btn-icon">
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </span>
            </span>
            <p class="h1" innerHTML={props.title} />
          </div>
          <div class="engagements-nav">
            <a href={props.prevLink} class="btn">
              <i aria-hidden="true" class="cog-icon">
                <IconArrowBack />
              </i>
              <Show
                when={props.isFirstEnga}
                fallback={<span>Engagement précédent</span>}
              >
                <span>Nos engagements</span>
              </Show>
            </a>
            <a href={props.nextLink} class="btn">
              <Show
                when={props.isLastEnga}
                fallback={<span>Engagement suivant</span>}
              >
                <span>Nos engagements</span>
              </Show>
              <i aria-hidden="true" class="cog-icon">
                <IconArrowForward />
              </i>
            </a>
          </div>
          <div class="ctas">
            <a href="#futur-logement" class="btn">
              Trouvez votre futur logement
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
